export const routes = {
  homePage: '/',
  network: '/network',
  features: '/features',
  reviews: '/reviews',
  security: '/security',
  pricing: '/pricing',
  aboutUs: '/about-us',
  contact: '/contact',
  compareProviders: '/compare-cdn-providers',
  getStarted: '/get-started',
  termsAndConditions: '/legal/terms-and-conditions',
  termsAndConditionsPrevious: '/legal/terms-and-conditions/previous',
  acceptableUsePolicy: '/legal/acceptable-use-policy',
  privacyPolicy: '/legal/privacy-policy',
  cookiePolicy: '/legal/cookie-policy',
  serviceLevelAgreement: '/legal/service-level-agreement',
  trust: '/legal/trust',
  tlsTest: '/tls-test',
  tlsTestResult: '/tls-test/result',
  videoCdn: '/video-cdn',
  blog: '/blog',
  blogTlsImprovingHttps: '/blog/improving-webperf-security-tls-1-3',
  japanCdn: '/japan-cdn',
  cdn77HasYourBack: '/cdn77-has-your-back',
  bugBounty: '/bug-bounty',
  nab: '/nab',
};

const CLIENT_URL = process.env.NEXT_PUBLIC_CLIENT_URL;

export const externalRoutes = {
  coreruleset: 'https://coreruleset.org/',
  datacamp: 'https://datacamp.co.uk/',
  client: {
    dashboard: CLIENT_URL,
    login: `${CLIENT_URL}/login`,
    support: `${CLIENT_URL}/support`,
    knowledgeBase: `${CLIENT_URL}/support/knowledgebase/introduction-to-cdn/what-is-a-cdn`,
    fileRules: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/custom-rules-for-specific-file-types`,
    errorResponse: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/custom-html-pages-for-http-error-responses`,
    clusterIntegrations: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/custom-logging-and-log-cluster-integrations`,
    cacheKeys: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/customizable-cache-keys`,
    pathHandling: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/custom-parameter-based-path-handling`,
    urlRedirects: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/customizable-url-redirects`,
    responseManipulation: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/response-manipulation`,
    originProtection: `${CLIENT_URL}/support/knowledgebase/security-measures/origin-protection`,
    securityMeasure: `${CLIENT_URL}/support/knowledgebase/security-measures/ssl-and-tls`,
    sslTls: `${CLIENT_URL}/support/knowledgebase/security-measures/ssl-and-tls`,
    encodingTranscoding: `${CLIENT_URL}/support/knowledgebase/video-on-demand/packaging-and-transcoding`,
    liveStreaming: `${CLIENT_URL}/support/knowledgebase/live-streaming/live-streaming-introduction`,
    liveToVod: `${CLIENT_URL}/support/knowledgebase/live-streaming/live-to-vod`,
    rateLimiting: `${CLIENT_URL}/support/knowledgebase/security-measures/rate-limiting`,
    requestCollapsing: `${CLIENT_URL}/support/knowledgebase/custom-rules-and-configurations/request-collapsing`,
    multilayerCache: `${CLIENT_URL}/support/knowledgebase/caching-and-http-headers/multi-layer-cache`,
    cdnStorage: `${CLIENT_URL}/support/knowledgebase/cdn77-object-storage/cdn77-object-storage-introduction`,
    gdprGpa: `${CLIENT_URL}/files/cdn77-gdpr-dpa.pdf`,
    paymentSettings: `${CLIENT_URL}/billing/payment-settings`,
  },
};

export const endPoints = {
  userIp: '/public/user/ip',
  tlsTest: '/public/tls/test',
  resendVerificationEmail: (id: string) => `/customer/${id}/resend-verification-email` as const,
  sendInquiry: '/inquiry/send',
  triggerJobsPipeline: '/public/build/cdn77-jobs',
} as const;
